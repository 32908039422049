import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { catchError } from 'rxjs/operators';

import { AppService } from '../../app.service';
import { CmsService } from 'src/app/services/cms.service';
import { CmsDataService } from 'src/app/services/cms-data.service';
import { ClaimsResponse } from 'src/app/shared/claimsResponse.model';
import { Image } from 'src/app/shared/image.model';
import { Texte } from 'src/app/shared/texte.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  error = false;
  email = '';
  cdPresta = '';
  confirmation: string;
  token: string;
  bouton: string;
  logo: string;
  subject: string;
  definition: string;
  marque: string;
  company: string;
  optins: string[];
  errorDesinscription: string;
  libelleError = '';
  tokenIsOK = false;
  textsLoaded = false;
  sent = false;
  className: string;

  constructor(
    private readonly service: AppService,
    private readonly route: ActivatedRoute,
    private readonly cmsService: CmsService,
    private readonly cmsDataService: CmsDataService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params.token && params.cdPresta) {
        this.cdPresta = params.cdPresta;
        this.token = params.token;
        this.service.getDecryptToken(this.cdPresta, this.token).subscribe(
          (infoToken: ClaimsResponse) => {
            this.email = infoToken.email;
            this.setBrand(infoToken.marque);
            this.setOptin(infoToken.optins);
            this.setCompany(infoToken.company);
            this.loadCmsData();
            if (!infoToken.confirmation || infoToken.confirmation === 'false') {
              this.unsubscribe();
            } else {
              this.tokenIsOK = true;
            }
          },
          () => {
            console.log("getDecryptToken KO");
            this.error = true;
            this.errorProcess();
          }
        );
      } else {
        console.log("params token cdPresta  KO");
        this.setBrand('rena');
        this.setCompany('dc');
        this.error = true;
        this.errorProcess();
      }
      this.loadTextes(null);
    });
  }


  errorProcess(){
    this.setBrand('rena');
    this.setCompany('dc');

    const logoKey = 'stopcomm.logo.' + this.marque + '.' + this.company;
    this.cmsService.getImageByKeyContains(logoKey).subscribe((images: Image[]) => {
      this.logo = this.cmsDataService.getImageUrlByKey(images, logoKey);
    });
  }

  unsubscribe() {
    this.service
      .updtateOptins(this.cdPresta, this.token)
      .pipe(
        catchError((e) => {
          console.log("unsubscribe KO");
          this.error = true;
          this.libelleError = this.errorDesinscription;
          return e;
        })
      )
      .subscribe(() => {
        this.tokenIsOK = false;
        this.sent = true;
      });
  }

  private setBrand(marque: string) {

    if (marque == 'NI' || marque == 'niss' || marque === 'NISS') {
      this.marque = 'niss';
    } else {
      this.marque = 'rena';
    }

    this.className = this.marque;
  }

  private setCompany(company: string) {
    this.company = company;
  }

  private setOptin(optins: string[]) {
    this.optins = optins;
  }

  private loadCmsData() {
    const key = this.optins +'.'+ this.marque + '.' + this.company;
    this.cmsService.getTexteByKeyContains(key).subscribe((textes: Texte[]) => {
      this.loadTextes(textes);
    });

    this.cmsService.getTexteByKeyContains('stopcomm.optin.subject.'+this.optins).subscribe((textes: Texte[]) => {
      this.subject = this.cmsDataService.getTexteWithKey(textes, 'stopcomm.optin.subject.'+this.optins);
    });

    this.cmsService.getTexteByKeyContains('stopcomm.optin.definition.'+this.optins).subscribe((textes: Texte[]) => {
      this.definition = this.cmsDataService.getTexteWithKey(textes, 'stopcomm.optin.definition.'+this.optins);
    });

    const logoKey = 'stopcomm.logo.' + this.marque + '.' + this.company;
    this.cmsService.getImageByKeyContains(logoKey).subscribe((images: Image[]) => {
      this.logo = this.cmsDataService.getImageUrlByKey(images, logoKey);
    });


  }

  private loadTextes(textes: Texte[]) {
    this.confirmation = this.cmsDataService.getTexteWithSuffix(
      textes,
      'stopcomm.confirmation',
      this.optins, this.marque, this.company
    );
    this.bouton = this.cmsDataService.getTexteWithSuffix(textes, 'stopcomm.bouton', this.optins, this.marque, this.company);
    this.libelleError = this.cmsDataService.getTexteWithSuffix(textes, 'stopcomm.erreur', this.optins, this.marque, this.company);
    this.errorDesinscription = this.cmsDataService.getTexteWithSuffix(
      textes,
      'stopcomm.erreur-desincription',
      this.optins, this.marque, this.company
    );
    this.textsLoaded = true;
  }
}

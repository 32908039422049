import { AppConfig } from 'src/app/app.config';
import { Texte } from '../shared/texte.model';
import { Image } from '../shared/image.model';

export class CmsDataService {
  texteDefaultValues: { [key: string]: string } = {};
  imageDefaultValues: { [key: string]: string } = {};

  constructor(private readonly config: AppConfig) {
    this.texteDefaultValues = this.config.getCmsDefaultData('textesParDefaut') as { [key: string]: string };
    this.imageDefaultValues = this.config.getCmsDefaultData('imagesParDefaut') as { [key: string]: string };
  }

  getTexteWithSuffix(listCms: Texte[], key: string, optins: string[], marque: string, company: string): string {
    let valueWithCdPrestaAndBrand = '';
    let defaultValue = '';
    let keyWithCdPrestaAndBrand: string;
    keyWithCdPrestaAndBrand = key+"."+optins+"."+marque+"."+company;
    keyWithCdPrestaAndBrand = keyWithCdPrestaAndBrand.toLowerCase();
    valueWithCdPrestaAndBrand =
      listCms && listCms.find((texte: Texte) => texte.cle === keyWithCdPrestaAndBrand)
        ? listCms.find((texte: Texte) => texte.cle === keyWithCdPrestaAndBrand).valeur
        : '';
    defaultValue = this.texteDefaultValues[key];
    return valueWithCdPrestaAndBrand || defaultValue;
  }

  getTexteWithKey(listCms: Texte[], key: string): string {
    let valueWithCdPrestaAndBrand = '';
    let defaultValue = '';
    let keyWithCdPrestaAndBrand: string;
    keyWithCdPrestaAndBrand = key;
    keyWithCdPrestaAndBrand = keyWithCdPrestaAndBrand.toLowerCase();
    console.log("getImageUrlByKey("+keyWithCdPrestaAndBrand+")");
    valueWithCdPrestaAndBrand =
      listCms && listCms.find((texte: Texte) => texte.cle === keyWithCdPrestaAndBrand)
        ? listCms.find((texte: Texte) => texte.cle === keyWithCdPrestaAndBrand).valeur
        : '';
    defaultValue = this.texteDefaultValues[key.toLowerCase()];
    return valueWithCdPrestaAndBrand || defaultValue;
  }

  getImageWithSuffix(listCms: Image[], key: string): Image {
    let valueWithPath: any;
    let keyWithPath: string;
     keyWithPath = key.toLowerCase();
    console.log("keyWithPath"+keyWithPath);
    valueWithPath =
      listCms && listCms.find((image: Image) => image.cle === keyWithPath)
        ? listCms.find((image: Image) => image.cle === keyWithPath)
        : listCms.find((image: Image) => image.cle === keyWithPath);
    return valueWithPath;
  }

  getImageUrlByKey(list: Image[], key: string) {
    console.log("getImageUrlByKey("+key+")");
    const localImage = this.getImageWithSuffix(list, key);
    if (localImage) {
      if (!localImage.image) {
        // On est sur une image renseignée par URL dans Strapi
        return localImage.url;
      } else {
        // On est sur une image uploadée via Strapi
        return this.getImagesUrl() + localImage.image.url;
      }
    } else {
      return '';
    }
  }

  getImagesUrl() {
    return this.config.getConfig('cmsApiUploadEndpoint');
  }
}

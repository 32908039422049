import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { AppConfig } from 'src/app/app.config';

@Injectable()
export class CmsService {
  listTextes: any;
  listImages: any;

  constructor(private readonly http: HttpClient, private readonly config: AppConfig) {}

  getImageByKeyContains<Image>(keyContains: string): Observable<Image[]> {
    keyContains = keyContains.toLowerCase();
    const url = `${this.config.getConfig('cmsApiEndpoint')}/strapi/image?cle_contains=${keyContains}`;
    return this.http.get<Image[]>(url).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getTexteByKeyContains<Texte>(keyContains: string): Observable<Texte[]> {
    keyContains = keyContains.toLowerCase();
    const url = `${this.config.getConfig('cmsApiEndpoint')}/strapi/texte?cle_contains=${keyContains}`;
    return this.http.get<Texte[]>(url).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }
}
